const postAlert = () => {
    // Función para obtener parámetros de la URL
    function obtenerParametroUrl(nombre) {
        nombre = nombre.replace(/[\\[]/, '\\\\[').replace(/[\\]]/, '\\\\]');
        let regex = new RegExp('[\\?&]' + nombre + '=([^&#]*)');
        let resultados = regex.exec(location.search);
        return resultados === null ? '' : decodeURIComponent(resultados[1].replace(/\+/g, ' '));
    }

    // Función para mostrar la tostada
    function mostrarTostada() {
        let estado = obtenerParametroUrl('state');
        let mensaje = obtenerParametroUrl('message');

        let color;
        if (estado === '1') {
            color = 'success';
        } else {
            color = 'danger';
        }

        let toastHtml = `
            <div id="liveToast" class="toast align-items-center text-bg-${color}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
                <div class="d-flex">
                    <div class="toast-body">
                        ${mensaje}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        `;

        $('#toastContainer').html(toastHtml);
        // Obtenemos toast insertada
        const toastLiveExample = document.getElementById('liveToast');
        // Creamos toast bootstrap
        const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);
        // Mostramos toast bootstrap
        toastBootstrap.show();
    }

    // Verificamos que haya parametros en la URL y que exista '.card-combination__actions'
    if(document.location.search !== '' && $('.card-combination__actions').length ){
        mostrarTostada();
    }

}

export default postAlert;