import html2canvas from 'html2canvas';

const elementToImage = () => {
    let $btnCapture = $('.captureBtn');
    let $miniBanner = $('.card-combination__banner');

    if( $btnCapture.length &&  $miniBanner.length){
        $btnCapture.on('click', function(event){
            event.preventDefault();
            // console.log('Click captura')

            let jsVanillaElement = $(this).parents('.card').find('.card-combination__banner').get(0);

            let linkHref = $(this).attr('href');

            html2canvas(jsVanillaElement).then(function(canvas) {
                let imageData = canvas.toDataURL('image/png');
                // document.body.appendChild(canvas);

                // Enviar imagen servidor
                $.ajax({
                    type: 'POST',
                    url: '/guardar_imagen/',
                    data: {
                        image_data: imageData
                    },
                    success: function(response) {
                        console.log('Imagen guardada con éxito en el servidor');
                        // Retomamos comportamiento normal link
                        window.location = linkHref;
                    },
                    error: function (jqXHR, estado, error) {
                        console.log("jqXHR: ", jqXHR);
                        console.log("estado: ", estado);
                        console.log("error ", error);
                    },
                });
            });
        });
    }
}

export default elementToImage;