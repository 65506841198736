import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import cardCombination from './functions/cardCombination';
import elementToImage from "./functions/elementToImage";
import postAlert from './functions/postAlert';

/**
 * Lighten or Darken Color
 *
 * The CSS preprocessors Sass and LESS can take any color and darken() or
 * lighten() it by a specific value. But no such ability is built into
 * JavaScript. This function takes colors in hex format (i.e. #F06D06, with or
 * without hash) and lightens or darkens them with a value.
 *
 * @param {String} colorCode The hex color code (with or without # prefix).
 * @param {Int} amount
 * 
 * Example:
 * 
 * // Lighten
 * let NewColor = LightenDarkenColor("#F06D06", 20);
 * 
 * // Darken
 * let NewColor = LightenDarkenColor("#F06D06", -20);
 */
function LightenDarkenColor(colorCode, amount) {
    let usePound = false;
 
    if (colorCode[0] == "#") {
        colorCode = colorCode.slice(1);
        usePound = true;
    }
 
    let num = parseInt(colorCode, 16);
 
    let r = (num >> 16) + amount;
 
    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }
 
    let b = ((num >> 8) & 0x00FF) + amount;
 
    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }
 
    let g = (num & 0x0000FF) + amount;
 
    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }
 
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

/*==================== FLUID TYPE SCALE ====================*/
const breakpoints = {
    mobile: 576,
    desktop: 1920
}
// if you set your root font size to 62.5%, then 1rem equals 10px.
let remValue = 10;

let defaultMinBp = breakpoints.mobile;
let defaultMaxBp = breakpoints.desktop;

function toRems(value){
    let valueRems = (value / remValue) * 1;
    return valueRems;
}

function clamped(minpx, maxpx, minBp= defaultMinBp, maxBp = defaultMaxBp){
    let slope = ( maxpx - minpx ) / ( maxBp - minBp );
    // console.log("slope ", slope);
    let slopevw = Math.round(( (slope*100) + Number.EPSILON) * 100) / 100;
    // console.log("slopevw ", slopevw);
    let interceptRems = Math.round(( toRems(minpx - slope * minBp) + Number.EPSILON) * 100) / 100;

    let minRems = Math.round(( toRems(minpx) + Number.EPSILON) * 100) / 100;
    let maxRems = Math.round(( toRems(maxpx) + Number.EPSILON) * 100) / 100;

    return `clamp(${minRems}rem, ${slopevw}vw + ${interceptRems}rem, ${maxRems}rem);`;
}

/*==================== CAROUSEL ====================*/

function initSliderBanner($element) {
    $element.addClass('owl-carousel').owlCarousel({
      items: 1,
      nav: false,
      dots: true,
      loop: true,
    //   autoplay: true,
    //   autoplayTimeout: 8000,
    //   autoplayHoverPause: true,
      autoWidth: true,
      stagePadding: 0,
      margin: 0,
      responsive: {
        992: {
            nav: true, 
        }
      }
    });
}

function containerFontSize(contenedor, texto, multiplicador){
    // let contenedor = document.getElementById("contenedor");
    // let texto = document.getElementById("texto");
    
    // Obtener el ancho y alto del contenedor
    let contenedorWidth = contenedor.offsetWidth;
    let contenedorHeight = contenedor.offsetHeight;
    
    // Calcular el tamaño máximo del texto basado en el ancho y alto del contenedor
    let maxWidth = contenedorWidth * 0.9; // 90% del ancho del contenedor
    let maxHeight = contenedorHeight * 0.9; // 90% del alto del contenedor
    
    multiplicador =  multiplicador ? parseInt(multiplicador) : 1;

    // Determinar el tamaño final del texto
    // let fontSize = Math.min(maxWidth / texto.offsetWidth, maxHeight / texto.offsetHeight) * parseFloat(window.getComputedStyle(texto).fontSize);
    let fontSize = Math.min(maxWidth / texto.offsetWidth, maxHeight / texto.offsetHeight) * multiplicador;

    
    // Aplicar el tamaño calculado al texto
    // texto.style.fontSize = fontSize + "px";'
    return fontSize;
}

/*==================== CUSTOM BANNER ====================*/

function getPosXClass(position){
    switch(position){
        case 'left': return 'ail';
        case 'center': return 'aic';
        case 'right': return 'air';
    }
}

function getPosYClass(position){
    switch(position){
        case 'top': return 'jct';
        case 'center': return 'jcc';
        case 'bottom': return 'jcb';
    }
}

function getAndSetBannerParams(){
    $('.banner__wrapper').each(function(){
        let isSlider = $(this).attr('data-slider');
        let txtColor = $(this).attr('data-color');
        let posHorizontal = $(this).attr('data-posx');
        let posVertical = $(this).attr('data-posy');
        let fontSize = $(this).attr('data-fsize') ? $(this).attr('data-fsize').split('px')[0] : '18';
        let fontFamily = $(this).attr('data-ffamily');

        let verticalClass = getPosYClass(posVertical);
        let horizontalClass= getPosXClass(posHorizontal);
        
        // Si es slider iniciamos carousel
        if(isSlider == 'True'){
            initSliderBanner($(this));
            
            // Seteamos color navegación owl
            let colorClaro = LightenDarkenColor(txtColor, 20);
            let colorOscuro = LightenDarkenColor(txtColor, -20);
            $(this).find('.owl-nav button.owl-prev').css('background-color',  colorOscuro );
            $(this).find('.owl-nav button.owl-next').css('background-color',  colorOscuro );
            
            $(this).find('.owl-dots button').css('background-color', colorClaro );
        }

        let $txtContainer = $(this).find('.banner__txt');
        
        // Seteamos posición vertical
        $txtContainer.addClass(verticalClass);

        // Seteamos posición horizontal
        $txtContainer.addClass(horizontalClass);

        // Seteamos color titulo y descripción
        $txtContainer.css('color', txtColor);

        // Calculamos tamaño fuente titulo
        let titleFontSize = containerFontSize( $txtContainer.get(0), $txtContainer.find('.banner__title').get(0),  fontSize);

        // Calculamos tamaño fuente desc, igual a 1.5 veses menor que la del titulo
        let descFontSize = titleFontSize / 1.5;

        // Seteamos tamaño fuente titulo y descripción
        $txtContainer.find('.banner__title').css({
            // 'font-size': `${parseInt(fontSize)*1.7}px`,
            'font-family': fontFamily,
            'font-size': `${titleFontSize}px`
        });
        
        // clamped(25, parseInt(fontSize)*1.7);
        
        $txtContainer.find('.banner__desc').css({
            // 'font-size': `${fontSize}px`,
            'font-family': fontFamily,
            'font-size': `${descFontSize}px`
        });
        // clamped(16, fontSize);
        // let styleTemplate = `<style type="text/css">
        //     .banner__title{
        //         font-size: ${clamped(25, parseInt(fontSize)*1.7)}
        //     }
        //     .banner__desc{
        //         font-size: ${clamped(16, parseInt(fontSize))}
        //     }
        // </style>`;
        // // console.log("estilo: ",styleTemplate)

        // $('head').append(styleTemplate);
        // let styleTemplate = `<style type="text/css">
        //     .banner__title{
        //         font-size: ${ containerFontSize( $txtContainer.get(0), $(this).find('.banner__title').get(0), 1.7 )}
        //     }
        //     .banner__desc{
        //         font-size: ${ containerFontSize( $txtContainer.get(0), $(this).find('.banner__desc').get(0) )}
        //     }
        // </style>`;

    });
}

/*==================== vh trick ====================*/
let timeoutVh = false;
let delay = 250; // delay after event is "complete" to run callback
let timeoutVw = false;

function setVh(){
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function setVw(){
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}

/*==================== WINDOW RESIZE ====================*/
window.addEventListener('resize', function () {
    // clear the timeout
    this.clearTimeout(timeoutVh);
    // start timing for event "completion"
    timeoutVh =  setTimeout(setVh(), delay);

    this.clearTimeout(timeoutVw);
    timeoutVh =  setTimeout(setVw(), delay);
});


/*==================== DOCUMENT READY ====================*/
document.addEventListener('DOMContentLoaded', function () {
    setVh();
    setVw();
    getAndSetBannerParams();
    // cardCombination();
    elementToImage();
    postAlert();
});